












import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'

import { DescriptionListData, DescriptionListModule } from '../DescriptionList.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<DescriptionListModuleUi>({ name: 'DescriptionListModuleUi' })
export class DescriptionListModuleUi extends AbstractModuleUi<DescriptionListModule> {
  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent && !!this.content.data
  }

  public get listData (): DescriptionListData[] {
    return this.content.data
  }
}

export default DescriptionListModuleUi
